body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ant-desgin components */
/* in your CSS/SCSS file */
.right-aligned-menu-items {
  display: flex;
  justify-content: flex-end; /* Align menu items to the right */
  background-color: #27272A; /* Set the background color of the menu bar */
  padding-left: 3%;
  font-size: 18px;
}

.right-aligned-menu-items .ant-menu-item-selected,
.right-aligned-menu-items .ant-menu-item-selected a {
  color: white !important; /* Ensures that the text color is white when selected */
}

.centered-menu {
  display: flex;
  justify-content: center;
  font-size: 20px;

}

/* To center the text of each menu item, if needed */
.ant-menu-horizontal > .ant-menu-item {
  text-align: center;
}


.nav-link {
  color: black;
  font-size: 2vh;
  text-decoration: none;
}

.splash__image {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Take full height of its parent to enable vertical centering */
}

.custom-collapse {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-collapse .ant-collapse-header {
  font-size: 1.5em;
  text-align: center;
  width: 100%; 
  display: flex; 
  justify-content: center;
  align-items: center;
}

.custom-collapse .ant-collapse-content {
  text-align: center;
  width: 100%;
}

.custom-collapse .ant-collapse-item {
  width: 95%;
  margin: 0 auto;
}

.customDashedButton {
  border-color: black !important;
}

.customDashedButton:hover {
  border-color: #26756B !important;
}

.customBlogButton {
  border-color: black !important;
}

.ant-table-cell {
  font-size: 1.5vh !important;
}

.table-row-light {
  background-color: #f7f7f7; /* Light grey */
}

.table-row-dark {
  background-color: #ffffff; /* White */
}

.citation-block {
  padding: 20px;
  margin-top: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}